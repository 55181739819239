import 'quill/dist/quill.core.css'
import { FormLabel } from './form-label'

import { Editor } from './text-editor'

export type RichTextEditorProps = {
  label?: string
  initialValue: string
  className?: string
  required?: boolean
  showCount?: boolean
  minChars?: number
  isInvalid?: string | boolean
  isReadonly?: boolean
  placeholder?: JSX.Element
  onChange: (value: string) => void
  onBlur?: () => void
  onFocus?: () => void
}

export function RichTextEditor({
  label,
  initialValue,
  className,
  required,
  showCount,
  minChars,
  isInvalid,
  isReadonly,
  placeholder,
  onChange,
  onBlur,
  onFocus,
  id,
}: RichTextEditorProps & { id?: string }) {
  // const quillRef = useRef<Quill>(null)

  return (
    <div className={className}>
      {label && (
        <FormLabel htmlFor={id} required={required}>
          {label}
        </FormLabel>
      )}
      <Editor
        placeholder={placeholder}
        initialValue={initialValue}
        onChange={(htmlString) => {
          onChange?.(htmlString)
        }}
      />
      {/* <ReactQuill
        forwardedRef={quillRef}
        // style={{ height: 300 }}
        value={initialValue}
        // placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        isReadonly={isReadonly}
        modules={{
          clipboard: {
            matchVisual: false,
          },
          placeholder: { html: placeholder },
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike'], // toggled buttons
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['blockquote', 'code-block'],
            [{ indent: '-1' }, { indent: '+1' }],
            ['clean'],
          ],
        }}
      /> */}
    </div>
  )
}
